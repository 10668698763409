import { format } from 'date-fns/format'
import { parse } from 'date-fns/parse'
import { mapFlyCabinClass } from '@/components/TripActivity/utils'
import { RecentFlightSearches } from '@/components/TripActivity/types'

const ORIGINAL_DATE_FORMAT = 'yyyy-MM-dd'
const LONG_DATE_FORMAT = 'EEE, LLL dd, yyyy'
const SHORT_DATE_FORMAT = 'yyyyMMdd'

// When HP_RT_SEARCHES is VARIANT, we use transformRecentSearchTripData to transform data
export default function transformRecentSearchTripData(
  mostRecentFlightSearchData: ReadonlyArray<RecentFlightSearches>
) {
  const {
    originAirportInfo,
    destinationAirportInfo,
    tripType,
    travelStartDate,
    travelEndDate,
    cabinClass,
    numberOfAdults,
    numberOfChildren,
    listingsUrl
  } = mostRecentFlightSearchData[0]

  const {
    city: originCity,
    state: originState,
    code: originCityCode,
    name: originName
  } = originAirportInfo
  const {
    city: destinationCity,
    state: destinationState,
    code: destinationCityCode,
    name: destinationName
  } = destinationAirportInfo

  const isRoundTrip = tripType === 'Round Trip'
  const subheadline = isRoundTrip ? 'Round trip' : 'One way'

  const startDateLongFormat = format(
    parse(travelStartDate, ORIGINAL_DATE_FORMAT, new Date()),
    LONG_DATE_FORMAT
  )

  const startDateShortFormat = format(
    parse(travelStartDate, ORIGINAL_DATE_FORMAT, new Date()),
    SHORT_DATE_FORMAT
  )

  const endDateLongFormat = isRoundTrip
    ? format(
        parse(travelEndDate, ORIGINAL_DATE_FORMAT, new Date()),
        LONG_DATE_FORMAT
      )
    : undefined

  const endDateShortFormat = isRoundTrip
    ? format(
        parse(travelEndDate, ORIGINAL_DATE_FORMAT, new Date()),
        SHORT_DATE_FORMAT
      )
    : undefined

  const flyTravelersCount = {
    adultCount: numberOfAdults,
    childCount: numberOfChildren
  }

  const searchData = {
    subheadline,
    startDateLongFormat,
    startDateShortFormat,
    endDateLongFormat,
    endDateShortFormat,
    originState: originState || '',
    originCity: originCity || originName,
    originCityCode,
    destinationState: destinationState || '',
    destinationCity: destinationCity || destinationName,
    destinationCityCode,
    flyTravelersCount,
    cabinClass: mapFlyCabinClass(cabinClass),
    listingsUrl
  }

  return searchData
}
