import useBootstrapData from '@/hooks/useBootstrapData'
import useRecentSearchCard from '@/hooks/useRecentSearchCard'
import hasRecentSearchFlightData from '../helper/hasRecentSearchFlightData'

export default function useGetMostRecentFlightSearch() {
  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn }
  } = useBootstrapData()

  const mostRecentFlightSearchData = useRecentSearchCard(
    clientGUID,
    { appName, appVersion },
    ['FLY'],
    signedIn
  )

  if (!hasRecentSearchFlightData(mostRecentFlightSearchData)) {
    return []
  }

  return mostRecentFlightSearchData
}
