import {
  SearchProductDataFiltered,
  MostRecentFlightSearches,
  RecentTripSearches
} from '../../types'

export default function hasRecentSearchFlightData(
  data: ReadonlyArray<SearchProductDataFiltered | RecentTripSearches>
): data is MostRecentFlightSearches {
  return data.length > 0 && data[0]?.productType === 'FLY'
}
