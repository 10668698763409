import fetchNearbyAirports from '@/shared-utils/fetch-nearby-airports'
import { useState, useEffect } from 'react'
import getAirportsWithin40Miles from '@/shared-utils/get-airports-within-40-miles'
import analytics from '@/shared-utils/analytics'
import useBootstrapData from './useBootstrapData'

type FetchStateType = 'PENDING' | 'COMPLETE'

const useNearbyAirports = () => {
  const [nearbyAirportCodes, setNearbyAirportCodes] = useState<string[]>([])
  const [progress, setProgress] = useState<FetchStateType>('PENDING')
  const {
    locale: { latitude, longitude, countryCode }
  } = useBootstrapData()

  useEffect(() => {
    const getNearbyAirports = async () => {
      const nearbyAirportsData = await fetchNearbyAirports(latitude, longitude)

      const originAirportCodesWithin40Miles = getAirportsWithin40Miles(
        nearbyAirportsData,
        countryCode
      )

      if (nearbyAirportsData.length === 0) {
        analytics.log({
          message: `could not find any nearby airports ${JSON.stringify({
            countryCode
          })}`
        })
      } else if (originAirportCodesWithin40Miles.length === 0) {
        analytics.log({
          message: `could not find any nearby airports around 40 miles ${JSON.stringify(
            {
              countryCode,
              originAirportCodesWithin40Miles
            }
          )}`
        })
      }
      setNearbyAirportCodes(originAirportCodesWithin40Miles)
      setProgress('COMPLETE')
    }
    void getNearbyAirports()
  }, [countryCode, latitude, longitude])

  return { nearbyAirportCodes, progress }
}

export default useNearbyAirports
