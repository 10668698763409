import {
  PackageSearchParamsType,
  PackageTypeCode
} from '@/components/TripActivity/types'

export default function generatePackageListingUrl({
  cabinClass,
  destinationCityCode,
  endDateShortFormat,
  flyTravelersCount,
  originCityCode,
  startDateShortFormat,
  packageTypeCode
}: PackageSearchParamsType & { packageTypeCode: PackageTypeCode }) {
  const { adultCount, infantCount, childCount } = flyTravelersCount

  const requiredParams = {
    origin: originCityCode,
    destination: destinationCityCode,
    'departure-date': startDateShortFormat,
    'package-type-code': packageTypeCode,
    'origin-type': 'AIRPORT',
    'destination-type': 'AIRPORT',
    'num-adults': adultCount.toString(),
    'num-children': childCount.toString(),
    'num-lap-infants': infantCount.toString(),
    'cabin-class': cabinClass
  }

  const urlParams = new URLSearchParams(requiredParams)

  return `/shop/search/?${urlParams.toString()}${
    endDateShortFormat ? `&return-date=${endDateShortFormat}` : ''
  }`
}
