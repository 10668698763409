import { CabinClassType, FlyTravelersCountType } from '../../types'

export default function generateFlightsLisitngUrl({
  cabinClass,
  destinationCityCode,
  endDateShortFormat,
  flyTravelersCount,
  originCityCode,
  startDateShortFormat
}: {
  cabinClass: CabinClassType
  destinationCityCode: string
  endDateShortFormat?: string
  flyTravelersCount: FlyTravelersCountType
  originCityCode: string
  startDateShortFormat: string
}) {
  const { adultCount, childCount, infantCount } = flyTravelersCount
  const flightListingURL = `/m/fly/search/${originCityCode}-${destinationCityCode}-${startDateShortFormat}${
    endDateShortFormat
      ? `/${destinationCityCode}-${originCityCode}-${endDateShortFormat}`
      : ''
  }`
  const roundTrip = '0000'
  const oneWay = '00'
  const requiredParams = {
    'no-date-search': 'false',
    sbsroute: 'slice1',
    'search-type': endDateShortFormat ? roundTrip : oneWay,
    'num-adults': adultCount.toString(),
    'num-children': childCount.toString(),
    'num-infants': infantCount.toString(),
    'cabin-class': cabinClass
  }

  const urlParams = new URLSearchParams(requiredParams)

  return `${flightListingURL}/?${urlParams.toString()}`
}
