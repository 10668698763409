import React from 'react'
import { Flex, Text } from 'pcln-design-system'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

function Line() {
  return (
    <Flex
      px="5px"
      py={0}
      mt={2}
      mx="10px"
      bg="text.base"
      height="2px"
      maxWidth="26px"
      style={{ flex: 1 }}
    />
  )
}

const NoWrapDateText = styled(Text)`
  ${themeGet('mediaQueries.md')} {
    white-space: nowrap;
  }
`

export default function DateBox({
  title,
  date,
  originCode,
  destinationCode
}: {
  title: string
  date: string
  originCode: string
  destinationCode: string
}) {
  return (
    <Flex
      flexDirection="column"
      bg="background.light"
      justifyContent="center"
      textAlign="center"
      borderRadius="xl"
      p={2}
      width={['50%', null, null, '161px']}
    >
      <NoWrapDateText fontSize={0} color="text.light" bold>
        {title}
      </NoWrapDateText>
      <Text textStyle="heading6">{date}</Text>
      <Flex justifyContent="center" width="auto">
        <Text textStyle="heading6">{originCode}</Text>
        <Line />
        <Text textStyle="heading6">{destinationCode}</Text>
      </Flex>
    </Flex>
  )
}
