import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Button,
  Box,
  Checkbox,
  Flex,
  Label,
  Text,
  Tooltip
} from 'pcln-design-system'
import { PackagesOutlineGreenOnGreen } from '@pcln/brand'
import { PackageTypeCode } from '../types'

const HotelOption = {
  name: 'add a hotel',
  value: 'H',
  label: 'Add a hotel'
} as const

const CarOption = {
  name: 'add a car',
  value: 'C',
  label: 'Add a car'
} as const

const productOptions = [HotelOption, CarOption]

const StyledTooltip = styled(Tooltip)`
  white-space: nowrap;
`

type CheckboxChangeHandler = (
  productCode: (typeof HotelOption)['value'] | (typeof CarOption)['value'],
  name: (typeof HotelOption)['name'] | (typeof CarOption)['name'],
  checked: boolean
) => void

function PackagesIcon() {
  return (
    <Flex
      borderRadius="2xl"
      width="36px"
      height="36px"
      bg="highlight.light"
      justifyContent="center"
      alignItems="center"
    >
      <PackagesOutlineGreenOnGreen size="22px" />
    </Flex>
  )
}

function BundleAndSaveCheckboxes({
  packageTypeCode,
  onCheckboxChange
}: {
  packageTypeCode: PackageTypeCode
  onCheckboxChange: CheckboxChangeHandler
}) {
  return productOptions.map(option => {
    const isChecked = packageTypeCode.includes(option.value)
    return (
      <Box key={option.name} mt="2px" pr={[1, 2]}>
        <Label fontSize={0} color="text.base" fontWeight="normal">
          <Checkbox
            aria-label={option.label}
            name={option.name}
            value={option.value}
            checked={isChecked}
            unselectedColor="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onCheckboxChange(option.value, option.name, e.target.checked)
            }}
          />
          {option.label}
        </Label>
      </Box>
    )
  })
}

export default function BundleHugWithCheckboxes({
  onBookABundleClick
}: {
  onBookABundleClick: (packageTypeCode: PackageTypeCode) => void
}) {
  const [showTooltip, setShowToolTip] = useState(false)
  const [packageTypeCode, setPackageTypeCode] = useState<PackageTypeCode>('A')
  function isPackageTypeCode(code: string): code is PackageTypeCode {
    return ['A', 'AH', 'AC', 'AHC', 'ACH'].includes(code)
  }
  function updatePackageOnCheckboxChange(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...[value, _, checked]: Parameters<CheckboxChangeHandler>
  ) {
    const newPackageCodes = checked
      ? `${packageTypeCode}${value}`
      : packageTypeCode.replace(value, '')

    if (isPackageTypeCode(newPackageCodes)) {
      setPackageTypeCode(newPackageCodes)
    }
  }

  useEffect(() => {
    if (showTooltip && packageTypeCode !== 'A') {
      setShowToolTip(false)
    }
  }, [showTooltip, setShowToolTip, packageTypeCode])

  return (
    <Flex
      height="100px"
      justifyContent={['unset', null, null, 'right']}
      pt={[4, null, null, 0]}
      mt={['0px', null, null, '-25px', null]}
      mr={[0, null, null, 4]}
      mb={[4, null, null, 3]}
      alignItems={['center']}
    >
      <Flex
        width={['100%', null, null, 'auto']}
        bg="background.lightest"
        borderRadius="xl"
        p={3}
        pt="40px"
        flexDirection={['column', null, null, 'row']}
        style={{ gap: '8px' }}
        boxShadowSize="sm"
      >
        <Flex alignItems="center">
          <PackagesIcon />
          <Box ml={2} mr={3}>
            <Text color="success.dark" textStyle="paragraphBold">
              BUNDLE AND SAVE
            </Text>

            <Text color="success.dark" textStyle="caption">
              Save an average of $240 per person.
            </Text>
          </Box>
        </Flex>
        <Flex width="auto" alignItems="center">
          {showTooltip && (
            <StyledTooltip bg="warning" bottom left mt={3}>
              Please select a bundle option
            </StyledTooltip>
          )}
          <BundleAndSaveCheckboxes
            packageTypeCode={packageTypeCode}
            onCheckboxChange={(value, name, checked) =>
              updatePackageOnCheckboxChange(value, name, checked)
            }
          />
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Button
            type="button"
            color="secondary.base"
            width={1}
            onClick={() => {
              return packageTypeCode === 'A'
                ? setShowToolTip(true)
                : onBookABundleClick(packageTypeCode)
            }}
          >
            Book a Bundle
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
