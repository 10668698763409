import React from 'react'
import BundleHug from './BundleHug'
import FlightsRecentSearchCard, {
  isFlyRecentSearchData,
  isFlyTravelersCountType
} from './FlightsRecentSearchCard'
import {
  PackageSearchParamsType,
  FlyRecentSearchData,
  PackageTypeCode,
  FlightsRecentSearchData
} from '../types'
import {
  fireOnBookABundleCheckboxClick,
  fireFlightsOnContinueClickEvent
} from '../ga4'
import generateFlightsListingUrl from './helper/generateFlightsListingUrl'
import generatePackageListingUrl from './helper/generatePackageListingUrl'

function onBookABundleClick(
  packageTypeCode: PackageTypeCode,
  packageSearchParams: PackageSearchParamsType
) {
  fireOnBookABundleCheckboxClick(packageTypeCode)

  const searchUrl =
    packageTypeCode === 'A'
      ? generateFlightsListingUrl(packageSearchParams)
      : generatePackageListingUrl({
          ...packageSearchParams,
          packageTypeCode
        })

  window.open(searchUrl)
}

function onBookABundleClickAndOpen(
  packageTypeCode: PackageTypeCode,
  listingsUrl: string
) {
  fireOnBookABundleCheckboxClick(packageTypeCode)

  window.open(listingsUrl)
}

export default function FlightRecentSearchWithCheckbox({
  searchData
}: {
  searchData: FlyRecentSearchData | FlightsRecentSearchData
}) {
  const {
    flyTravelersCount,
    cabinClass,
    destinationCityCode,
    originCityCode,
    startDateShortFormat,
    endDateShortFormat
  } = searchData

  return (
    <FlightsRecentSearchCard
      searchData={searchData}
      onContinueSearchClick={fireFlightsOnContinueClickEvent}
    >
      <BundleHug
        onBookABundleClick={packageTypeCode => {
          // When HP_RT_SEARCHES is DEFAULT, then isFlyRecentSearchData is true
          if (isFlyRecentSearchData(searchData)) {
            if (isFlyTravelersCountType(flyTravelersCount)) {
              const packageSearchParams = {
                flyTravelersCount,
                cabinClass,
                destinationCityCode,
                originCityCode,
                startDateShortFormat,
                endDateShortFormat
              }

              onBookABundleClick(packageTypeCode, packageSearchParams)
            }
          } else {
            const { listingsUrl } = searchData
            onBookABundleClickAndOpen(packageTypeCode, listingsUrl)
          }
        }}
      />
    </FlightsRecentSearchCard>
  )
}
