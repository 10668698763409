import React, { useEffect } from 'react'
import { Carousel } from 'pcln-carousel'
import { Heading, Box, Flex } from 'pcln-design-system'
import { PromoListingsCard } from '@pcln/marketing'
import { Loader } from '@pcln/loader'
import useFetchPriceDropData from '@/hooks/useFetchPriceDropData'
import { motion } from 'framer-motion'
import useDetectBrowser from '@/hooks/useDetectBrowser'
import {
  calculateTripDuration,
  fireGA4ViewPromotionEvent,
  formatDate,
  handleClickOnPromoCard
} from './utils'

const MotionDiv = motion.div

export default function FlyPriceDrop() {
  const { priceDropData, progress } = useFetchPriceDropData()
  const isBrowser = useDetectBrowser()
  const showPriceDrop = priceDropData.length >= 2

  useEffect(() => {
    if (showPriceDrop) {
      fireGA4ViewPromotionEvent()
    }
  }, [showPriceDrop])

  if (progress === 'PENDING') {
    return (
      <Flex
        justifyContent="space-around"
        data-testid="fly-price-drop-loader-dots"
        height="120px"
      >
        <Box mt={4} width="90px">
          {isBrowser && <Loader />}
        </Box>
      </Flex>
    )
  }

  if (!showPriceDrop) {
    return null
  }

  const CAROUSEL_PRERENDER_PLACEHOLDER_HEIGHT = ['316px', '294px']

  return (
    <MotionDiv
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <Box height={CAROUSEL_PRERENDER_PLACEHOLDER_HEIGHT}>
        <Heading color="text.heading" mb={3} textStyle="heading3">
          Price Drop Deals Near You
        </Heading>
        <Carousel
          arrowsPositions="top"
          arrowsPosition="side"
          currentSlide={0}
          displayArrowsMobile={false}
          slideSpacing={1}
          visibleSlides={[1.2, 2.5, 3.1, 3.5, 3.5]}
          mobileVisibleSlides={[1.2, 2.1]}
          layout={priceDropData.length === 2 ? '50-50' : null}
        >
          {priceDropData.map((data, index) => {
            const departDate = formatDate(data.departDate)
            const returnDate = formatDate(
              data.returnDate ? data.returnDate : data.departDate
            )

            const tripDuration = calculateTripDuration(
              data.departDate,
              data.returnDate
            )

            return (
              <PromoListingsCard
                key={`fly-price-drop-${index + 1}`}
                buttonText="Search Flight Times"
                dealPrice={data.dealPrice}
                departDate={departDate}
                destinationAirportCode={data.destinationAirportCode}
                destinationCity={data.destinationCity}
                destinationImg={`${data.destinationImage}?opto&auto=avif,webp&width=192&height=144`}
                handleClick={() => handleClickOnPromoCard(data.landingPageUrl)}
                originAirportCode={data.originAirportCode}
                returnDate={returnDate}
                strikePrice={data.strikePrice}
                savingsText={`${data.percentageDrop}% PRICE DROP`}
                tripDuration={tripDuration}
                tripType="Round Trip"
              />
            )
          })}
        </Carousel>
      </Box>
    </MotionDiv>
  )
}
