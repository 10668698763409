import React from 'react'
import { Button } from 'pcln-design-system'

function fireOnButtonClick() {
  window.open('/next-profile/trips?tab=recent_activity')
}

export default function ViewAllActivityButton({
  onClick
}: {
  onClick: () => void
}) {
  return (
    <Button
      type="button"
      data-testid="view-all-button-bottom"
      mt={['51px', null, null, '32px']}
      variation="white"
      width={[3 / 5, null, null, null, 1 / 5]}
      onClick={() => {
        onClick()
        fireOnButtonClick()
      }}
    >
      View All Recent Activity
    </Button>
  )
}
