import { PRIMARY_HEADING_FALLBACK } from '@/components/Flights/constants'
import { isModifyFlightDetails } from '@/shared-utils/flight-rebook-helpers'
import useBootstrapData from '../../hooks/useBootstrapData'
import useContentfulSearchFormHeadlines from '../../hooks/useContentfulSearchFormHeadlines'
import useCityNameInFormHeadlineForSEMFlights from '../../hooks/useCityNameInFormHeadlineForSEMFlights'

function useFlightDetailsHeadline() {
  const searchForm = useContentfulSearchFormHeadlines('searchForm')
  const semPrePopHeadline = useCityNameInFormHeadlineForSEMFlights()
  const { flightRebookOrModifyDetails } = useBootstrapData()

  const isModify =
    flightRebookOrModifyDetails &&
    isModifyFlightDetails(flightRebookOrModifyDetails)
  const modifyDestinationCity = isModify
    ? flightRebookOrModifyDetails.destinationCity
    : ''

  const modifyHeadline = `Modify your trip to ${modifyDestinationCity}`
  const headline = isModify
    ? modifyHeadline
    : semPrePopHeadline || searchForm?.headline || PRIMARY_HEADING_FALLBACK

  return headline
}

export default useFlightDetailsHeadline
