import { useEffect, useState } from 'react'
import { GraphResponse, GraphResponseData, PriceDropData } from '@/types'
import config from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import {
  GQL_QUERY_ERROR,
  INVALID_GQL_QUERY_RESPONSE,
  CATCH_BLOCK_ERROR,
  RESPONSE_OVER_299
} from '@/constants/errorMessages'
import useBootstrapData from './useBootstrapData'
import { jsonContentTypeHeader } from '../server/constants'
import useNearbyAirports from './useNearbyAirports'
import useSeti from './useSeti'

type PriceDropError = {
  priceDropError: string
}

type PriceDropResult = {
  priceDrops: PriceDropData[]
}

type FlyPriceDropQueryResult = {
  flyPriceDrops: PriceDropError | PriceDropResult
}

function roundPriceDropData(data: PriceDropData[]) {
  return data
    .filter(priceDropDataRaw => Number(priceDropDataRaw.dealPrice)) // prevent NaN from showing up in UI
    .map(priceDropData => {
      const dealPriceRounded = Math.round(Number(priceDropData.dealPrice))
      return {
        ...priceDropData,
        dealPrice: dealPriceRounded.toString()
      }
    })
}

function isValidGraphQLResponse(
  payload: unknown
): payload is GraphResponse<FlyPriceDropQueryResult> {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'flyPriceDrops' in payload.data
    ) {
      const { flyPriceDrops } = payload.data
      if (
        flyPriceDrops &&
        typeof flyPriceDrops === 'object' &&
        ('priceDropError' in flyPriceDrops || 'priceDrops' in flyPriceDrops)
      ) {
        return true
      }
    }
  }
  return false
}

function isPriceDropQueryResult(
  payload: GraphResponse<FlyPriceDropQueryResult>
): payload is GraphResponseData<FlyPriceDropQueryResult> {
  if (
    'data' in payload &&
    typeof payload.data === 'object' &&
    payload.data &&
    'flyPriceDrops' in payload.data &&
    typeof payload.data.flyPriceDrops === 'object' &&
    payload.data.flyPriceDrops &&
    (('priceDropError' in payload.data.flyPriceDrops &&
      typeof payload.data.flyPriceDrops.priceDropError === 'string') ||
      ('priceDrops' in payload.data.flyPriceDrops &&
        Array.isArray(payload.data.flyPriceDrops.priceDrops)))
  ) {
    return true
  }
  return false
}

function isPriceDropResult(
  payload: PriceDropResult | PriceDropError
): payload is PriceDropResult {
  if (payload && 'priceDrops' in payload && Array.isArray(payload.priceDrops)) {
    return true
  }
  return false
}
type FetchPriceDropDataStatus = 'PENDING' | 'SUCCESS' | 'FAILED'

const useFetchPriceDropData = () => {
  const [priceDropData, setPriceDropData] = useState<PriceDropData[]>([])
  const [progress, setProgress] = useState<FetchPriceDropDataStatus>('PENDING')
  const { appName, appVersion } = useBootstrapData()
  const { nearbyAirportCodes, progress: nearbyAirportCodesProgress } =
    useNearbyAirports()

  const shouldUseStellate = useSeti('HP_FLY_PRICE_DROP_STELLATE') === 'VARIANT'

  useEffect(() => {
    let startTime: number
    async function fetchPriceDrop() {
      const variables = {
        priceDropRequestOptions: {
          offset: 0,
          productType: 'FLY',
          flyPriceDropRequestOptions: {
            destinationAirportCodes: [],
            originAirportCodes: nearbyAirportCodes
          },
          resultSize: 12
        }
      }

      const graphConfig = shouldUseStellate
        ? 'stellate-graph-cdn'
        : 'pcln-graph'

      const requestData = {
        requestUrl: graphConfig,
        requestBody: JSON.stringify({
          variables
        }),
        requestMethod: 'POST'
      }

      try {
        const { url, timeout } = config.client[graphConfig]
        const requestUrl = `${url}?gqlOp=flyPriceDrops`
        const options = {
          method: 'POST',
          signal: AbortSignal.timeout(timeout),
          headers: {
            ...jsonContentTypeHeader,
            'apollographql-client-name': appName,
            'apollographql-client-version': appVersion
          },
          body: JSON.stringify({
            query: `
              query flyPriceDrops($priceDropRequestOptions: PriceDropRequestOptions!) {

                flyPriceDrops(priceDropRequestOptions: $priceDropRequestOptions) {
                ...on PriceDropResult {
                    priceDrops {
                        originCity
                        originAirportCode
                        originAirportCountryName
                        originAirportStateCode
                        destinationCity
                        destinationDmaName
                        destinationAirportCode
                        destinationAirportCountryName
                        destinationAirportStateCode
                        destinationImage
                        departDate
                        returnDate
                        strikePrice
                        dealPrice
                        percentageDrop
                        outboundAirlines
                        inboundAirlines
                        landingPageUrl
                        landingPageSlingshotUrl
                        promotionalCopy
                        alertDate
                        expiryDate
                    }
                }
                ...on PriceDropErr {
                    priceDropError
                }
              }
            }`,
            variables
          })
        }
        startTime = performance.now()
        const response = await fetch(requestUrl, options)
        const { status, statusText, type, url: responseUrl } = response

        if (!response.ok) {
          analytics.logError({
            message: RESPONSE_OVER_299,
            responseUrl,
            status,
            statusText,
            type,
            serviceName: 'flyPriceDrops',
            ...requestData
          })
        } else {
          const responseData = await response.json()

          if (isValidGraphQLResponse(responseData)) {
            if (isPriceDropQueryResult(responseData)) {
              const { flyPriceDrops } = responseData.data
              if (isPriceDropResult(flyPriceDrops)) {
                const flyPriceDropsRounded = roundPriceDropData(
                  flyPriceDrops.priceDrops
                )
                setPriceDropData(flyPriceDropsRounded)
                setProgress('SUCCESS')
              } else {
                analytics.logError({
                  error: flyPriceDrops.priceDropError,
                  message: GQL_QUERY_ERROR,
                  serviceName: 'flyPriceDrops'
                })
                setProgress('FAILED')
              }
            } else {
              analytics.logError({
                error: JSON.stringify(responseData.errors),
                message: GQL_QUERY_ERROR,
                serviceName: 'flyPriceDrops'
              })
              setProgress('FAILED')
            }
          } else {
            analytics.logError({
              message: INVALID_GQL_QUERY_RESPONSE,
              serviceName: 'flyPriceDrops',
              ...requestData
            })
            setProgress('FAILED')
          }
        }
      } catch (error) {
        const elapsedTime = performance.now() - startTime
        analytics.logError({
          elapsedTime,
          error: JSON.stringify(error),
          message: CATCH_BLOCK_ERROR,
          serviceName: 'flyPriceDrops',
          ...requestData
        })

        setProgress('FAILED')
      }
    }

    if (nearbyAirportCodesProgress === 'COMPLETE') {
      void fetchPriceDrop()
    }
  }, [
    appName,
    appVersion,
    nearbyAirportCodes,
    nearbyAirportCodesProgress,
    shouldUseStellate
  ])

  return { priceDropData, progress }
}

export default useFetchPriceDropData
