import { NearbyAirportType } from './fetch-nearby-airports'

function compareByProximity(a: NearbyAirportType, b: NearbyAirportType) {
  return a.proximity - b.proximity
}

export default function getAirportsWithin40Miles(
  nearbyAirports: NearbyAirportType[],
  countryCode: string
) {
  const AIRPORTS_PROXIMITY_IN_MILES = 40
  const airportsWithinFortyMiles = nearbyAirports
    .sort(compareByProximity)
    .filter(
      airportInfo =>
        airportInfo.proximity <= AIRPORTS_PROXIMITY_IN_MILES &&
        airportInfo.countryCode === countryCode
    )

  if (airportsWithinFortyMiles?.length === 0) {
    return []
  }

  const originAirportCodes = airportsWithinFortyMiles?.map(
    airportInfo => airportInfo.airportCode
  )

  return originAirportCodes
}
