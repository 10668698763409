import React from 'react'
import { Box } from 'pcln-design-system'
import StyledHeading from '@/components/StyledHeading'
import { MODIFY_SUBHEADLINE } from '../constants'

function SubHeadline() {
  return (
    <Box
      color="primary.light"
      px={12}
      py={2}
      mt={[2, null, 0]}
      mb={[2, null, 12]}
      borderRadius="xl"
    >
      <StyledHeading
        tag="h2"
        color="text.light"
        text={MODIFY_SUBHEADLINE}
        fontSize={0}
      />
    </Box>
  )
}

export default SubHeadline
