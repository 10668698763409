import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import { differenceInDays } from 'date-fns/differenceInDays'
import analytics from '@/shared-utils/analytics'

const handleClickOnPromoCard = (landingPageUrl: string) => {
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'recommendation',
      page_name: 'landing',
      product_name: 'air',
      promotion_name: 'air',
      creative_name: 'price_drop'
    }
  })
  window.open(landingPageUrl, '_blank')
}

const fireGA4ViewPromotionEvent = () => {
  analytics.fireGA4Event({
    event: 'view_promotion',
    attributes: {
      type: 'recommendation',
      page_name: 'landing',
      product_name: 'air',
      promotion_name: 'air',
      creative_name: 'price_drop'
    }
  })
}

const calculateTripDuration = (departDate: string, returnDate: string) => {
  if (!departDate) return null
  if (!returnDate) return 'One Way'
  if (departDate === returnDate) return 'Same Day'

  const days = differenceInDays(new Date(returnDate), new Date(departDate))
  return `${days} ${days === 1 ? 'Day' : 'Days'}`
}

const formatDate = (date: string) => {
  if (!date) return null
  try {
    const parsedDate = parseISO(date)
    return format(parsedDate, 'MMM dd')
  } catch (error) {
    return null
  }
}

export {
  calculateTripDuration,
  formatDate,
  handleClickOnPromoCard,
  fireGA4ViewPromotionEvent
}
