import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { theme } from 'pcln-design-system'
import ViewAllActivityButton from './ViewAllAcitvityButton'
import { MostRecentFlightSearches, RecentFlightSearches } from '../types'
import StyledWrapper from '../TripActivity.styled'
import { fireFlightRecentSearchViewAllButtonClick } from '../ga4'
import FlightRecentSearchWithCheckbox from './FlightRecentSearchWithCheckbox'
import transformRecentSearchData from './helper/transformRecentSearchData'
import transformRecentSearchTripData from './helper/transformRecentSearchTripData'

function isNewRecentSearchQuery(
  data: MostRecentFlightSearches
): data is ReadonlyArray<RecentFlightSearches> {
  return data.length > 0 && 'tripType' in data[0]
}

export default function Wrapper({
  mostRecentFlightSearchData
}: {
  mostRecentFlightSearchData: MostRecentFlightSearches
}) {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg as string})`
  })

  const searchData = isNewRecentSearchQuery(mostRecentFlightSearchData)
    ? transformRecentSearchTripData(mostRecentFlightSearchData)
    : transformRecentSearchData(mostRecentFlightSearchData)

  return (
    <section>
      <StyledWrapper borderRadius="2xl" bg="primary.light" mb="30px">
        <FlightRecentSearchWithCheckbox searchData={searchData} />
        {!isDesktop && (
          <ViewAllActivityButton
            onClick={fireFlightRecentSearchViewAllButtonClick}
          />
        )}
      </StyledWrapper>
    </section>
  )
}
