import config from 'isomorphic-config'

import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import { jsonContentTypeHeader } from '../server/constants'
import analytics from './analytics'

export type NearbyAirportType = {
  airportCode: string
  cityID: string
  cityCode: string
  cityName: string
  stateCode: string
  countryCode: string
  isoCountryCode: string
  timeZoneID: string
  searchDistance: number
  qsi: number
  id: string
  itemName: string
  type: string
  provinceName: string
  country: string
  lat: number
  lon: number
  score: number
  fromSavedSearch: boolean
  proximity: number
  poiCategoryTypeId: number
  gmtOffset: number
}

type AirNearbyAirportsRspBase = {
  elapsedTime?: string
  responseTimeStamp?: string
  referrals?: unknown[]
  pointOfSale?: {
    country: string
    currency: string
  }
}

type AirNearbyAirportsRspSuccess = AirNearbyAirportsRspBase & {
  nearbyAirPorts: NearbyAirportType[]
}

type AirNearbyAirportsRspError = AirNearbyAirportsRspBase & {
  error: {
    code: string
    desc: string
  }
}

type NearbyAirportBaseResponse = {
  resultCode: -1 | 0 | number
  processorID: string
}

type NearbyAirportValidResponse = NearbyAirportBaseResponse & {
  resultMessage: 'Success' | string
  airNearbyAirportsRsp: AirNearbyAirportsRspSuccess | AirNearbyAirportsRspError
}

export type NearbyAirportSuccessfulResponse = NearbyAirportBaseResponse & {
  resultMessage: 'Success'
  airNearbyAirportsRsp: AirNearbyAirportsRspSuccess
}

function isValidNearbyAirportResponse(
  payload: unknown
): payload is NearbyAirportValidResponse {
  if (
    payload &&
    typeof payload === 'object' &&
    'airNearbyAirportsRsp' in payload &&
    typeof payload.airNearbyAirportsRsp === 'object'
  ) {
    const data = payload.airNearbyAirportsRsp
    if (data && ('error' in data || 'nearbyAirPorts' in data)) {
      return true
    }
  }
  return false
}

function isNearbyAirportSuccessfulResponse(
  payload: NearbyAirportValidResponse
): payload is NearbyAirportSuccessfulResponse {
  if (
    'nearbyAirPorts' in payload.airNearbyAirportsRsp &&
    Array.isArray(payload.airNearbyAirportsRsp.nearbyAirPorts)
  ) {
    return true
  }
  return false
}

const url = config.client.fly.nearbyAirports

export default async function fetchNearbyAirports(
  latitude: number,
  longitude: number
) {
  const signalOption = isAbortSignalTimeoutSupported()
    ? { signal: AbortSignal.timeout(3500) }
    : {}
  const options = {
    ...signalOption,
    method: 'POST',
    headers: {
      ...jsonContentTypeHeader
    },
    body: JSON.stringify({
      airNearByAirportsReq: {
        latitude,
        longitude
      }
    })
  }

  const startTime = Date.now()
  try {
    const response = await fetch(url, options)
    const { status, statusText, type, url: urlValue } = response
    if (!response.ok) {
      const logObject = {
        response: {
          status,
          statusText,
          type,
          url: urlValue
        }
      }
      analytics.logError({
        message: `The nearby airport api response status is > 299 ${JSON.stringify(
          logObject
        )}`
      })
    } else {
      const responseData = await response.json()
      if (isValidNearbyAirportResponse(responseData)) {
        if (isNearbyAirportSuccessfulResponse(responseData)) {
          return responseData.airNearbyAirportsRsp.nearbyAirPorts
        }
        analytics.logError({
          message: `The nearby airport api response returned errors ${JSON.stringify(
            { responseData, nearbyAirportOption: options }
          )}`
        })
      } else {
        analytics.logError({
          message: `The nearby flights api response is not is not as per contract ${JSON.stringify(
            { responseData }
          )}`
        })
      }
    }
  } catch (error) {
    if ((error as Error).name === 'TimeoutError') {
      const elapsedTime = Date.now() - startTime
      analytics.logError({
        message: `Timeout reached while invoking nearby endpoint pws/v0/fly/c/airNearByAirport. ${JSON.stringify(
          { elapsedTime, error }
        )}`
      })
    } else {
      analytics.logError({
        message: `Failed to call nearby airport endpoint ${JSON.stringify({
          url,
          options,
          error
        })}`
      })
    }
  }
  return []
}
