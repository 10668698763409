import { format } from 'date-fns/format'
import { parse } from 'date-fns/parse'
import { mapFlyCabinClass } from '@/components/TripActivity/utils'
import {
  FlyOriginDestinationDataFiltered,
  FlySearchData
} from '@/components/TripActivity/types'

const ORIGINAL_DATE_FORMAT = 'yyyy-MM-dd'
const LONG_DATE_FORMAT = 'EEE, LLL dd, yyyy'
const SHORT_DATE_FORMAT = 'yyyyMMdd'

// When HP_RT_SEARCHES is DEFAULT, we use transformRecentSearchData to transform data
export default function transformRecentSearchData(
  mostRecentFlightSearchData: ReadonlyArray<
    FlySearchData<FlyOriginDestinationDataFiltered>
  >
) {
  const { originDestinationData } =
    mostRecentFlightSearchData[0].flyOriginDestinationData[0]

  const [originCity, originState] =
    originDestinationData.originCityData.cityName.split(',')
  const [destinationCity, destinationState] =
    originDestinationData.destinationCityData.cityName.split(',')

  const originCityCode = originDestinationData.originAirportData.airportCode
  const destinationCityCode =
    originDestinationData.destinationAirportData.airportCode

  const isRoundTrip = mostRecentFlightSearchData[0].flyTripType === 'ROUND_TRIP'
  const subheadline = isRoundTrip ? 'Round trip' : 'One way'

  const { startDate: tripStartDate } =
    mostRecentFlightSearchData[0].flyOriginDestinationData[0]

  const startDateLongFormat = format(
    parse(tripStartDate, ORIGINAL_DATE_FORMAT, new Date()),
    LONG_DATE_FORMAT
  )

  const startDateShortFormat = format(
    parse(tripStartDate, ORIGINAL_DATE_FORMAT, new Date()),
    SHORT_DATE_FORMAT
  )
  const { startDate: tripEndDate } =
    mostRecentFlightSearchData[0].flyOriginDestinationData?.[1] || {}

  const endDateLongFormat = isRoundTrip
    ? format(
        parse(tripEndDate, ORIGINAL_DATE_FORMAT, new Date()),
        LONG_DATE_FORMAT
      )
    : undefined

  const endDateShortFormat = isRoundTrip
    ? format(
        parse(tripEndDate, ORIGINAL_DATE_FORMAT, new Date()),
        SHORT_DATE_FORMAT
      )
    : undefined

  const cabinClass = mapFlyCabinClass(
    mostRecentFlightSearchData[0].flyCabinClass
  )

  const { flyTravelersCount } = mostRecentFlightSearchData[0]

  const searchData = {
    subheadline,
    startDateLongFormat,
    startDateShortFormat,
    endDateLongFormat,
    endDateShortFormat,
    originState,
    originCity: originCity.toLowerCase(),
    originCityCode,
    destinationState,
    destinationCity: destinationCity.toLowerCase(),
    destinationCityCode,
    flyTravelersCount,
    cabinClass
  }

  return searchData
}
