import { useState, useEffect } from 'react'

export default function useDetectBrowser() {
  const [isBrowser, setIsBrowser] = useState(false)
  useEffect(() => {
    setIsBrowser(true)
  }, [setIsBrowser])

  return isBrowser
}
