import { Button, Card, Flex, Heading, Text, theme } from 'pcln-design-system'
import { useMediaQuery } from 'react-responsive'
import { FlightsOutline } from '@pcln/brand'
import { User } from 'pcln-icons'
import React from 'react'
import styled from 'styled-components'
import DateBox from './DateBox'
import {
  FlightsRecentSearchData,
  FlightsTravelersCountType,
  FlyRecentSearchData,
  FlyTravelersCountType
} from '../types'
import generateFlightsListingUrl from './helper/generateFlightsListingUrl'
import { fireFlightRecentSearchViewAllButtonClick } from '../ga4'

const StyledCard = styled(Card)`
  box-sizing: content-box;
  display: flex;
  position: relative;
  &:hover {
    cursor: pointer;
    box-shadow: ${theme.shadows.lg};
  }
`

function fireOnContinueSearchClick(url: string) {
  window.open(url)
}

function Headline({
  originCity,
  originState,
  originCityCode,
  destinationCity,
  destinationState,
  destinationCityCode
}: {
  originCity: string
  originState: string
  originCityCode: string
  destinationCity: string
  destinationState: string
  destinationCityCode: string
}) {
  return (
    <span>
      <span style={{ textTransform: 'capitalize' }}>{originCity}</span>
      {originState && `, ${originState}`} ({originCityCode}) {`- `}
      <span style={{ textTransform: 'capitalize' }}>{destinationCity}</span>
      {destinationState && `, ${destinationState}`} ({destinationCityCode})
    </span>
  )
}

function TravelerSelction({
  flyTravelersCount
}: {
  flyTravelersCount: Readonly<{
    adultCount: number
    childCount: number
    infantCount?: number
  }>
}) {
  const { adultCount, infantCount, childCount } = flyTravelersCount
  return (
    <Flex flexDirection="column" alignItems="center">
      {adultCount >= 1 && (
        <Text textStyle="caption" textAlign="center" width="fit-content">
          {adultCount} {adultCount === 1 ? `Adult` : `Adults`}
        </Text>
      )}
      {infantCount && infantCount >= 1 && (
        <Text textStyle="caption" textAlign="center" width="fit-content">
          {infantCount} {infantCount === 1 ? `Infant` : `Infants`}
        </Text>
      )}
      {childCount >= 1 && (
        <Text textStyle="caption" textAlign="center" width="fit-content">
          {childCount} {childCount === 1 ? `Child` : `Children`}
        </Text>
      )}
    </Flex>
  )
}

export function isFlyTravelersCountType(
  data: FlyTravelersCountType | FlightsTravelersCountType
): data is FlyTravelersCountType {
  return 'infantCount' in data
}

export function isFlyRecentSearchData(
  data: FlyRecentSearchData | FlightsRecentSearchData
): data is FlyRecentSearchData {
  return 'infantCount' in data.flyTravelersCount
}

function FlightsTripActivity({
  searchData,
  children,
  onContinueSearchClick
}: {
  searchData: FlyRecentSearchData | FlightsRecentSearchData
  children?: React.ReactNode
  onContinueSearchClick: () => void
}) {
  const {
    subheadline,
    startDateLongFormat,
    startDateShortFormat,
    endDateLongFormat,
    endDateShortFormat,
    originState,
    originCity,
    originCityCode,
    destinationState,
    destinationCity,
    destinationCityCode,
    flyTravelersCount,
    cabinClass
  } = searchData

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg as string})`
  })

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Heading
          textStyle={['heading3', null, null, 'heading2']}
          color="text.heading"
          m={0}
          mb={3}
          as="h1"
        >
          Pick up where you left off
        </Heading>
        {isDesktop && (
          <Button
            data-testid="view-all-button-top-right"
            variation="white"
            onClick={fireFlightRecentSearchViewAllButtonClick}
          >
            View All Recent Activity
          </Button>
        )}
      </Flex>
      <StyledCard
        bg="background.lightest"
        borderRadius="xl"
        boxShadowSize="md"
        p={3}
        borderWidth={0}
      >
        <Flex
          flexDirection={['column', null, null, 'row']}
          width={1}
          justifyContent="space-between"
        >
          <Flex mb={[2, null]}>
            <Flex alignItems="center" px="27.5px" height="auto">
              <FlightsOutline size="50px" />
            </Flex>
            <Flex alignItems={['flex-start', null, null, 'center']}>
              <Flex
                flexDirection="column"
                width={['auto', null, null, '240px', '340px']}
                style={{ gap: '4px' }}
              >
                <Heading
                  textStyle={['heading5', null, null, 'heading4']}
                  textTransform="capitalize"
                  m={0}
                  as="h2"
                >
                  <Headline
                    originCity={originCity}
                    originState={originState}
                    originCityCode={originCityCode}
                    destinationCity={destinationCity}
                    destinationState={destinationState}
                    destinationCityCode={destinationCityCode}
                  />
                </Heading>
                <Text textStyle="caption" color="text.light" width="auto">
                  {subheadline} flight
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            flexDirection={['column', null, null, 'row']}
            style={{ gap: '8px' }}
          >
            <Flex style={{ gap: '8px' }}>
              <DateBox
                title="DEPART"
                date={startDateLongFormat}
                originCode={originCityCode}
                destinationCode={destinationCityCode}
              />
              {endDateLongFormat && (
                <DateBox
                  title="RETURN"
                  date={endDateLongFormat}
                  originCode={destinationCityCode}
                  destinationCode={originCityCode}
                />
              )}
            </Flex>

            <Flex
              flexDirection={['column', null, null, null, 'row']}
              style={{ gap: '8px' }}
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg="background.light"
                borderRadius="xl"
                py={3}
                px={4}
              >
                <User size="20px" color="text.light" />
                <TravelerSelction flyTravelersCount={flyTravelersCount} />
              </Flex>
              <Flex alignItems="center">
                <Button
                  type="button"
                  variation="subtle"
                  color="primary"
                  width={1}
                  onClick={() => {
                    onContinueSearchClick()
                    // When HP_RT_SEARCHES is DEFAULT, then isFlyRecentSearchData is true
                    if (isFlyRecentSearchData(searchData)) {
                      if (isFlyTravelersCountType(flyTravelersCount)) {
                        const packageSearchParams = {
                          cabinClass,
                          flyTravelersCount,
                          originCityCode,
                          destinationCityCode,
                          startDateShortFormat,
                          endDateShortFormat
                        }
                        fireOnContinueSearchClick(
                          generateFlightsListingUrl(packageSearchParams)
                        )
                      }
                    } else {
                      const { listingsUrl } = searchData
                      fireOnContinueSearchClick(listingsUrl)
                    }
                  }}
                >
                  Continue Search
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </StyledCard>
      {children}
    </>
  )
}

export default FlightsTripActivity
